<template>
<div class="Registry">
     <ContainerCard  :width="61">
        <div class="info">
        <div class="infomain">
          <div class="info-title">
              <span class="label">隐私保护</span>
         </div>
         <div class="standard">
            <p class="artContent" 
           >欢迎您访问我们的产品。 本应用APP （包括App等产品提供的服务，以下简称“产品和服务”）是由 本公司 （以下简称“我们”）开发并运营的。 确保用户的数据安全和隐私保护是我们的首要任务， 本隐私政策载明了您访问和使用我们的产品和服务时所收集的数据及其处理方式。请您在继续使用我们的产品前务必认真仔细阅读并确认充分理解本隐私政策全部规则和要点， 一旦您选择使用，即视为您同意本隐私政策的全部内容，同意我们按其收集和使用您的相关信息。 如您在在阅读过程中，对本政策有任何疑问，可联系我们的客服咨询， 请通过 产品中的反馈方式与我们取得联系。 如您不同意相关协议或其中的任何条款的，您应停止使用我们的产品和服务。本隐私政策帮助您了解以下内容：</p>
         </div>
          
          <div class="standard">
            <p class="artContent">一、我们如何收集和使用您的个人信息；</p>

          </div>

          <div class="standard">
            <p class="artContent">二、我们如何存储和保护您的个人信息；</p>

          </div>

          <div class="standard">
            <p class="artContent">三、我们如何共享、转让、公开披露您的个人信息；</p>

          </div>

          <div class="standard">
            <p class="artContent">四、我们如何使用 Cookie 和其他追踪技术；</p>

          </div>


          <div class="standard">
            <p class="artContent">一、我们如何收集和使用您的个人信息</p>
            <ul>
                <li >个人信息是指以电子或者其他方式记录的能够单独或者与其他信息， 结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。 我们根据《中华人民共和国网络安全法》和《信息安全技术个人信息安全规范》（GB/T 35273-2017） 以及其它相关法律法规的要求，并严格遵循正当、合法、必要的原则， 出于您使用我们提供的服务和/或产品等过程中而收集和使用您的个人信息。为接受我们全面的产品服务，您应首先注册一个用户账号，我们将通过它记录相关的数据。 您所提供的所有信息均来自于您本人在注册时提供的数据。 您准备使用的账户名、密码、您本人的联系方式， 我们可能通过发短信或者邮件的方式来验证您的身份是否有效。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">二、我们如何存储和保护您的个人信息</p>
            <ul>
                <li >作为一般规则，我们仅在实现信息收集目的所需的时间内保留您的个人信息。 我们会在对于管理与您之间的关系严格必要的时间内保留您的个人信息 （例如，当您开立帐户，从我们的产品获取服务时）。 出于遵守法律义务或为证明某项权利或合同满足适用的诉讼时效要求的目的， 我们可能需要在上述期限到期后保留您存档的个人信息，并且无法按您的要求删除。 当您的个人信息对于我们的法定义务或法定时效对应的目的或档案不再必要时， 我们确保将其完全删除或匿名化。我们使用符合业界标准的安全防护措施保护您提供的个人信息，并加密其中的关键数据， 防止其遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。 我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">三、我们如何共享、转让、公开披露您的个人信息</p>
            <p class="artContent">在管理我们的日常业务活动所需要时，为追求合法利益以更好地服务客户， 我们将合规且恰当的使用您的个人信息。出于对业务和各个方面的综合考虑， 我们仅自身使用这些数据，不与任何第三方分享。我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。 在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。 我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
              <ul>
                <li >1、与国家安全、国防安全直接相关的；</li>
                <li >2、与犯罪侦查、起诉、审判和判决执行等直接相关的；</li>
                <li >3、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</li>
                <li >4、您自行向社会公众公开的个人信息；</li>
                <li >5、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</li>
                <li >6、根据个人信息主体要求签订和履行合同所必需的；</li>
                <li >7、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</li>
                <li >8、法律法规规定的其他情形。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">四、我们如何使用 Cookie 和其他追踪技术</p>
            <ul>
                <li >为确保产品正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。 Cookie 通常包含标识符、产品名称以及一些号码和字符。 借助于 Cookie，我们能够存储您的偏好或商品等数据，并用以判断注册用户是否已经登录， 提升服务和产品质量及优化用户体验。我们出于不同的目的使用各种Cookie，包括：严格必要型Cookie、性能Cookie、营销Cookie和功能Cookie。 某些Cookie可能由外部第三方提供，以向我们的产品提供其它功能。 我们不会将 Cookie 用于本政策</li>
            </ul>
          </div>
        </div>
      </div>
     </ContainerCard>
</div>
  
</template>

<script>
import HomeNav from "../Layout/components/HomeNav.vue";
import Footer from "../Layout/components/Footer.vue";
import ContainerCard from "../../components/newContainerCard.vue";
export default {
    name:'Registry',
    data() {
        return{

        }
    },
    components:{ HomeNav , Footer ,ContainerCard }

}
</script>

<style scoped lang="scss">
// .Registry {
//     margin:  0 auto;
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
// }

// .registryText {
//     // display: flex;
//     // flex-direction:column;
//     // flex-wrap: wrap;
//     width: 756px;
//     background: red;
//     margin: 0 auto;
//     padding: 20px 50px 36px 50px;
//     font-size: 16px;
//     font-family: SourceHanSansCN-Regular, SourceHanSansCN;
//     font-weight: 400;
//     color: #404040;
//     line-height: 36px;
//     text-align: justify;
// }

.Registry {
    .info {
  margin-bottom: 20px;
  margin-top: 24px;
  .infomain {
    margin: 20px 0;
    ul {
        text-align: justify;
    }
    li {
        line-height: 24px;
    }
    .info-title {
        margin-bottom: 24px;
        text-align: center;
        img {
          width: 8px;
          height: 10px;
          margin-right: 12px;
        }
        .label {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 600;
          color: #101010;
          line-height: 24px;
        }
      }
    .standard {
    .artContent {
      margin-top: 20px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #404040;
      line-height: 36px;
      text-align: justify;
    }

    }
  }
}

}

</style>